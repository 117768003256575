import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { ReactTypeformEmbed } from 'react-typeform-embed';
import Layout from 'layout';
import { Dropdown, DropdownItem } from 'reactstrap';
import { media } from 'theme/breakpoints';
import Button, { StyledButton } from 'components/ui/button';
import Img from 'gatsby-image';
import { useLogoCompanies } from 'hooks/useLogoCompanies';
import checkmark from 'img/ui/checkmark-black.svg';
import illustration from 'img/startup-program.svg';
import sessionicon from 'img/index/sessionreplayIcon.svg';
import filtersicon from 'img/index/filtersIcon.svg';
import inspecticon from 'img/index/featureCards/badgeInspect.svg';
import arrowDown from 'img/ui/chevron-down.svg';
import { StyledDropdown, StyledDropdownMenu } from './pricing';

const HeroSection = styled.section`
  display: grid;
  grid-template-columns: 1fr minmax(400px, 550px);
  grid-column-gap: 60px;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
  & h1 {
    margin-bottom: 32px;
  }
  & p {
    font-size: 22px;
    line-height: 34px;
    margin-bottom: 40px;
  }
  & img {
    max-width: 560px;
  }
  ${media.desktopWide} {
    grid-template-columns: 1fr 1fr;
    & img {
      max-width: 100%;
    }
  }
  ${media.desktop} {
    & h1 {
      font-size: 36px;
      line-height: 48px;
      margin-bottom: 24px;
    }
    & p {
      font-size: 18px;
      line-height: 30px;
    }
  }
  ${media.tablet} {
    grid-column-gap: 0;
    margin-top: 2rem;
    grid-template-columns: 1fr;
    grid-template-rows: min-content;
    & img {
      display: none;
    }
  }
  ${media.mobile} {
    padding-top: 6.2rem;
    margin-top: 0;
    grid-row-gap: 52px;
  }
`;

const PricingBanner = styled.section`
  background-color: #f8f8f8;
  border-radius: 6px;
  padding: 46px 0;
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-column-gap: 105px;
  justify-content: center;
  margin-bottom: 40px;
  position: relative;
  ${media.tablet} {
    grid-template-columns: 1fr;
    padding: 40px;
    grid-row-gap: 24px;
    grid-template-rows: min-content min-content;
  }
  ${media.mobile} {
    margin-bottom: 30px;
  }
`;

const Price = styled.div`
  align-self: center;
  & p strong {
    font-weight: 800;
  }
  & p:first-child {
    text-decoration: line-through;
    color: #6a6a6a;
    font-size: 22px;
    margin-bottom: 0;
    line-height: normal;
    ${media.mobile} {
      font-size: 20px;
    }
  }
  & p:last-child {
    font-size: 48px;
    position: relative;
    line-height: normal;
    margin-bottom: 0;
    &::before,
    &::after {
      font-weight: 800;
      position: absolute;
    }
    & sup {
      font-size: 22px;
      margin-right: 4px;
    }
    & sub {
      font-size: 20px;
      margin-left: 4px;
    }
  }
`;

const Features = styled.ul`
  padding: 0;
  margin: 0;
  & li {
    font-size: 22px;
    margin-bottom: 2px;
  }
  ${media.tablet} {
    padding-left: 20px;
    & li {
      font-size: 18px;
    }
  }
`;

const Requirements = styled.section`
  text-align: center;
`;

const List = styled.ul`
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-template-rows: repeat(2, auto);
  grid-gap: 40px;
  justify-content: center;
  margin-top: 72px;
  margin-left: 60px;
  ${media.tablet} {
    margin-left: 0;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, auto);
    width: fit-content;
    margin: 54px auto 0;
  }
  & li {
    display: flex;
    align-items: center;
    & img {
      margin-right: 16px;
      max-width: 22px;
    }
    & p {
      font-weight: 400;
      font-size: 22px;
      line-height: normal;
      margin-bottom: 0;
      ${media.tablet} {
        font-size: 18px;
      }
    }
  }
`;

const FeatureCards = styled.section`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 33px;
  padding-top: 40px;
  ${media.desktop} {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, auto);
    grid-row-gap: 24px;
  }
  ${media.mobile} {
    padding-top: 30px;
  }
  & div {
    background-color: #f8f8f8;
    border-radius: 6px;
    padding: 40px 24px 24px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-height: 282px;
    ${media.desktop} {
      min-height: auto;
      padding: 40px;
    }
    &:nth-child(2) {
      padding: 30px 24px 24px;
      ${media.desktop} {
        padding: 30px 30px 40px;
      }
    }
    & img {
      max-width: min-content;
      height: auto;
      display: block;
      margin: 0 auto 16px;
    }
    & h3 {
      font-size: 24px;
      line-height: 36px;
      margin-bottom: 24px;
    }
    & p {
      margin-bottom: 0;
    }
  }
`;

const ApplyBanner = styled.section`
  background-color: #7dffef;
  border-radius: 6px;
  padding: 56px 72px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 6.5rem 0 120px;
  ${media.desktop} {
    flex-direction: column;
    padding: 56px;
  }
  ${media.mobile} {
    margin: 2rem 0 120px;
    padding: 32px 24px;
  }
`;

const ContentBox = styled.div`
  & h2 {
    font-size: 48px;
    margin-bottom: 32px;
  }
  & p {
    font-size: 22px;
    line-height: 34px;
    margin-bottom: 40px;
  }

  ${media.desktop} {
    text-align: center;
    & a {
      margin: 0 auto;
    }
    & ${StyledButton} {
      margin: 0 auto;
      display: block;
    }
  }
  ${media.mobile} {
    & h2 {
      font-size: 32px;
      margin-bottom: 24px;
    }
    & p {
      font-size: 18px;
      line-height: 30px;
      margin-bottom: 32px;
    }
  }
`;

const ImagesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-row-gap: 8px;
  grid-column-gap: 56px;
  margin-left: 85px;
  ${media.desktopWide} {
    margin-left: 40px;
    grid-column-gap: 24px;
  }
  ${media.desktop} {
    margin-top: 32px;
    margin-left: 0;
    grid-column-gap: 32px;
  }
  ${media.mobile} {
    grid-column-gap: 8px;
  }
  & .gatsby-image-wrapper {
    img {
      left: 50% !important;
      transform: translateX(-50%);
    }
  }
  img {
    margin: 0 auto;
    max-width: 138px;
    ${media.tablet} {
      max-width: 110px;
    }
    ${media.mobile} {
      max-width: 90px;
    }
  }
`;

const StyledCustomDropdown = styled(Dropdown)`
  position: absolute;
  right: 16px;
  top: 16px;
`;

const ExtendStyledDropdown = styled(StyledDropdown)`
  border: none;
  &:hover,
  &:active,
  &:focus {
    background-color: #fff !important;
    color: #000000 !important;
    box-shadow: none !important;
    border: none !important;
  }
`;

const requirements = [
  'Up to $1M in funding',
  'Less than 2 years old',
  '20 or fewer employees',
  'New or non-paying customer',
];

const StartupProgram = () => {
  const [currency, setCurrency] = useState('usd');
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const {
    rocketlink,
    livereacting,
    logicalplan,
    vitreo,
    scalablePress,
    spySerp,
  } = useLogoCompanies();

  const form = useRef();

  const handleOpenForm = () => {
    form.current.typeform.open();
  };

  return (
    <Layout
      canonical="/early-stage-program/"
      metaTitle="Early-stage Startup Program"
      metaDescription="Grow your early-stage startup with LiveSession. Save almost $1400 and boost your growth."
    >
      <div className="container">
        <HeroSection>
          <div>
            <h1>Take your early-stage startup to the next level</h1>
            <p>
              Starting your business adventure? Grow faster with LiveSession. Save money with our
              early-stage program.
            </p>
            <Button
              as="button"
              onClick={() => handleOpenForm()}
              style={{ position: 'reative', zIndex: 9999 }}
            >
              Apply now
            </Button>
          </div>
          <img src={illustration} alt="Hero Illustration" title="Grow faster with LiveSession" />
        </HeroSection>
        <PricingBanner>
          <StyledCustomDropdown isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)}>
            <ExtendStyledDropdown>
              <p>{currency}</p>
              <img
                src={arrowDown}
                alt="arrow"
                title="arrow down"
                style={{ transform: dropdownOpen && `rotate(180deg)` }}
              />
            </ExtendStyledDropdown>
            <StyledDropdownMenu>
              <DropdownItem onClick={() => setCurrency('usd')}>USD</DropdownItem>
              <DropdownItem onClick={() => setCurrency('eur')}>EUR</DropdownItem>
            </StyledDropdownMenu>
          </StyledCustomDropdown>
          <Price>
            <p>
              <strong> {`${currency === 'usd' ? '$ 2388' : '€ 2138'}`}</strong>
            </p>
            <p>
              <strong>
                <sup>{`${currency === 'usd' ? '$' : '€'}`}</sup>
                {`${currency === 'usd' ? '999' : '899'}`}
                <sub>/ 1st year</sub>
              </strong>
            </p>
          </Price>
          <Features>
            <li>
              <strong>25 000</strong> sessions monthly
            </li>
            <li>
              <strong>20</strong> team members
            </li>
            <li>
              <strong>5</strong> websites
            </li>
          </Features>
        </PricingBanner>
        <Requirements>
          <h2>Early-stage program requirements</h2>
          <List>
            {requirements.map((req) => (
              <li key={req}>
                <img src={checkmark} alt="checkmark" title="Checkmark" />
                <p>{req}</p>
              </li>
            ))}
          </List>
        </Requirements>
        <FeatureCards>
          <div>
            <img src={sessionicon} alt="Sessions" title="Session replays badge" />
            <h3>Learn more about your customers</h3>
            <p>See how users interact with your product.</p>
          </div>
          <div>
            <img src={filtersicon} alt="Filters" title="Filters badge" />
            <h3>Get insights faster</h3>
            <p>Analyzing behavior is easier with advanced filters.</p>
          </div>
          <div>
            <img src={inspecticon} alt="Inspect" title="Inspect mode badge" />
            <h3>Find spots for improvement</h3>
            <p>Make the right changes and boost conversions.</p>
          </div>
        </FeatureCards>
        <ApplyBanner>
          <ContentBox>
            <h2>Apply now</h2>
            <p>Join the early-stage program and grow like our customers.</p>
            <Button as="button" onClick={() => handleOpenForm()}>
              Apply now
            </Button>
          </ContentBox>
          <ImagesGrid>
            <img src={logicalplan.publicURL} alt="logicalplan" title="LogicalPlan" />
            <Img
              fluid={livereacting.childImageSharp.fluid}
              alt="livereacting"
              fadeIn
              title="LiveReacting"
              objectFit="contain"
            />
            <img src={vitreo.publicURL} alt="vitreo" title="Vitreo" />
            <Img
              fluid={spySerp.childImageSharp.fluid}
              alt="spyserp"
              fadeIn
              title="Spyserp"
              objectFit="contain"
            />
            <img src={scalablePress.publicURL} alt="scalablePress" title="Scalable Press" />
            <Img
              fluid={rocketlink.childImageSharp.fluid}
              alt="rocketlink"
              fadeIn
              title="Rocketlink"
              objectFit="contain"
            />
          </ImagesGrid>
        </ApplyBanner>
      </div>
      <ReactTypeformEmbed
        url="https://hello811074.typeform.com/to/o6Mura"
        autoOpen={false}
        ref={form}
        popup
        hideHeaders
        hideFooter
        style={{ top: '-100%' }}
      />
    </Layout>
  );
};

export default StartupProgram;
